import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import {
  Container,
  ThemeProvider,
  Breadcrumbs,
  Typography,
  Link,
} from "@mui/material"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import NavigateNextIcon from "@mui/icons-material/NavigateNext"
import ScheduleIcon from "@mui/icons-material/Schedule"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import theme from "../../theme.js"
//import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

import * as dayjs from "dayjs"

const renderOptions = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: node => {
      // render the EMBEDDED_ASSET as you need
      console.log(node)
      const { gatsbyImageData, description } = node.data.target
      return (
        <>
          <GatsbyImage
            image={getImage(gatsbyImageData)}
            alt={description}
            style={{ marginTop: "10px" }}
          />
          <Typography
            variant="body2"
            style={{ marginBottom: "15px", fontStyle: "italic" }}
          >
            {description}
          </Typography>
        </>
      )
    },
  },
}

const Article = ({ data }) => {
  const article = data.contentfulArticle
  console.log(data)
  return (
    <ThemeProvider theme={theme}>
      <Seo title={"Vinkoll - " + (article ? article.title : "Artikel")} />
      <Layout isHome={false}>
        <Container className="container">
          {article != null && (
            <>
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
                sx={{ mb: 3 }}
              >
                <Link underline="hover" key="1" color="inherit" href="/">
                  Vinkoll
                </Link>
                ,
                <Typography key="3" color="text.primary">
                  {article.title}
                </Typography>
                ,
              </Breadcrumbs>
              <article className="content">
                {article.featureImage ? (
                  <figure className="post-feature-image">
                    <img
                      src={article.featureImage.fields.file.url}
                      alt={article.title}
                    />
                  </figure>
                ) : null}
                <section className="post-full-content">
                  <Typography variant="h3">{article.title}</Typography>
                  <Typography variant="subtitle1">
                    <ScheduleIcon fontSize="14" style={{ marginRight: 5 }} />
                    Publicerad:
                    {dayjs(article.publishDate).format("YYYY-MM-DD")}
                  </Typography>
                  {/* The main post content */}
                  <section
                    style={{ marginTop: 25 }}
                    /*dangerouslySetInnerHTML={{
                        __html: documentToHtmlString(article.fields.body),
                      }}
                    /*/
                  >
                    {renderRichText(article.body, renderOptions)}
                  </section>
                </section>
              </article>
            </>
          )}
        </Container>
      </Layout>
    </ThemeProvider>
  )
}

export const data = graphql`
  query articleQuery($id: String) {
    contentfulArticle(id: { eq: $id }) {
      slug
      publishDate
      title
      body {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            title
            description
            gatsbyImageData(width: 1000)
            __typename
          }
        }
      }
    }
  }
`

Article.propTypes = {
  data: PropTypes.shape({
    contentfulArticle: PropTypes.object,
  }),
}

export default Article
